import React from 'react'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './statement.module.css'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.statement}>
        <p>
          The essence of my works is related to personal experiences, socio-political and environmental issues. They represent the challenges we face as humans and my concern for how Earth reacts to society's behavior. I use this conceptual framework to investigate the ever-changing complexity of the human condition and nature, my way to stimulate positive changes and raise awareness of environmental justice.
        </p>
        <p>
          I materialize these concepts in a variety of media, such as installation, ceramic, sculpture, mixed media and painting. The process is partly spontaneous, based on both current events and what the subconscious has stored throughout my life. 
        </p>
        <p>
          They are inspired by the idea that everything is connected, our state of mind, the severity and urgency of the environmental crisis and its complex and ubiquitous manifestations.
        </p>
        <p>
          My works continue to evolve in unique ways, where places and connections with different cultures have put me on a path of new challenges.
        </p>
        <p>
          Thanks to these experiences, I can discover and explore a new world of ideas and implement them with a variety of media of my interest, including found objects from places I have lived.
        </p>
      </div>
    </main>
    <Footer />
  </Layout>
)
